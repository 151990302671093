.fixed-menu {
    position: fixed;
    background: #fcfcfc;
    color: #000;
    height: 61px;
    width: 100%;
    left: 0;
    padding-left: 40px;
    padding-right: 40px;
    top: 0;
    z-index: 999;
    padding-top: 18px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.025);
      transform: translateY(-100%);
      visibility: hidden;
      transition: transform .4s cubic-bezier(0.6, 0.1, 0.1, 1), visibility .4s 0s ease;
    }

    .fixed-menu.open {
      visibility: visible;
      transform: translateY(00%);
      transition: transform .4s cubic-bezier(0.6, 0.1, 0.1, 1), visibility 0s ease;
    }

.menu .menu-item{
    float: left;
}

.menu-item:nth-of-type(1){
    padding-left: 0px;
}

.menu-item{
    padding-left: 30px;

}

.to-top{
    text-align: right;
}



.fixed-menu-articles {
    position: fixed;
    background: #fcfcfc;
    color: #000;
    height: 61px;
    width: 100%;
    left: 0;
    padding-left: 40px;
    padding-right: 40px;
    top: 0;
    z-index: 999;
    padding-top: 18px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.0);
    transition: all .4s cubic-bezier(0.6, 0.1, 0.1, 1);
    }


    .mobile-menu{
    display: block;
    }

    .mobile-menu-icon{
      display: none;
      cursor: pointer;
    }



    @media screen and (min-width: 601px) and (max-width: 1100px) {
      .mobile-top{
        display: none;
      }
          .mobile-menu-icon{
            display: block;
            position: absolute;
     right: 0px;
    top: 2px;
    padding: 15px;
    float: right;
      transform: rotate(0deg);
      transition: all .3s cubic-bezier(0.6, 0.1, 0.1, 1);
          }

          .mobile-menu-icon.open{
            transform: rotate(45deg);
          }

          .mobile-menu-icon svg{
            display: block;
            width: 25px;
            margin-top: -1px;
          }

      .mobile-menu p{
          font-size: 24px;
          padding-top: 10px;
          padding-bottom: 10px;
          border-top: 2px currentColor solid;
          border-bottom: 2px currentColor solid;
     width: calc(100vw - 50px)
      }

      .mobile-menu{
        display: none;
        transition: transform .7s cubic-bezier(0.6, 0.1, 0.1, 1);
        z-index: 1;
      }

      .mobile-menu.open{
        display: block;
        transform: translateX(0vh);
      }

      .menu {
        background-color: #fcfcfc;
        z-index: 1111;
        height: 100vh;
        margin-left: -30px;
        width: 100vw;
        box-shadow: 10px 0px 3px -3px rgba(0, 0, 0, .025);
      }

      .menu-item{
          padding-left: 15px;
     height: 52px;

      }.menu-item:nth-of-type(1){
          padding-left: 15px;
      }

      .mobile-menu-header{
      margin-left: -15px;
      z-index: 2;

      }

    }




        @media screen and (min-width: 0px) and (max-width: 600px) {
          .mobile-top{
            display: none;
          }
          .fixed-menu {
              position: fixed;
              background: #fcfcfc;
              color: #000;
              height: 53px;
              padding-top: 18px;
              }
              .fixed-menu-articles {
                  position: fixed;
                  background: #fcfcfc;
                  color: #000;
                  height: 53px;
                  padding-top: 18px;
                  }
              .mobile-menu-icon{
                display: block;
                position: absolute;
         right: 0px;
        top: 2px;
        padding: 15px;
        float: right;
          transform: rotate(0deg);
          transition: all .3s cubic-bezier(0.6, 0.1, 0.1, 1);
              }

              .mobile-menu-icon.open{
                transform: rotate(45deg);
              }

              .mobile-menu-icon svg{
                display: block;
                width: 20px;
                margin-top: -1px;
              }

          .mobile-menu p{
              font-size: 24px;
              width: 100vw;
              padding-top: 10px;
              padding-bottom: 10px;
              border-top: 2px currentColor solid;
              border-bottom: 2px currentColor solid;
         width: calc(100vw - 20px)
          }

          .mobile-menu{
            display: none;
            transition: transform .7s cubic-bezier(0.6, 0.1, 0.1, 1);
            z-index: 1;
          }

          .mobile-menu.open{
            display: block;
            transform: translateX(0vh);
          }

          .menu {
            background-color: #fcfcfc;
            z-index: 1111;
            height: 100vh;
            margin-left: -30px;
            width: 100vw;
            box-shadow: 10px 0px 3px -3px rgba(0, 0, 0, .025);
          }

          .menu-item{
              padding-left: 0px;
         height: 52px;

          }

          .mobile-menu-header{
          margin-left: -30px;
          z-index: 2;

          }

        }
