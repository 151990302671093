@font-face {
  font-family: 'HelveticaN';
  src: url(../assets/fonts/HelveticaNeue-Regular.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
}


@import "_partials/reset";
@import "_partials/base";
@import "_partials/grid";
@import "_partials/type";


@import "_partials/menu";
@import "_partials/footer";
@import "_partials/index";
@import "_partials/accordion";
@import "_partials/articles";
@import "_partials/article";
