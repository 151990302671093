body, html {
    background-color: #fcfcfc;
    z-index: 0;
    overscroll-behavior-y: none;

}

ol, ul {
    list-style: none
}

li {
    display: list-item;
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

caption, td, th {
    font-weight: 500;
    vertical-align: top;
    text-align: left
}

q {
    quotes: none
}

q:after, q:before {
    content: '';
    content: none
}

svg {
    overflow: hidden;
}

input, button, submit {
    border:none; }

header{
    z-index: 999;
}

body{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: calc(100% - 80px);
}

@media screen and (min-width: 0px) and (max-width: 699px) {

  body{
    width: calc(100% - 20px);
}

}

@media screen and (min-width: 700px) and (max-width: 1099px) {

  body{
    width: calc(100% - 40px);
}

}

.double-border{
    border-top: 2px currentColor solid;
    border-bottom: 2px currentColor solid;
    padding: 10px 0px;
}

.double-border-accordion{
    border-top: 2px currentColor solid;
    border-bottom: 2px currentColor solid;
    padding: 9px 0px;
    margin-top: -2px;
}

.double-border-accordion.open{
    border-bottom: 2px rgba(0,0,0,0.15) solid;


}

.top-border{
    border-top: 2px currentColor solid;
    padding-top: 10px;
     padding-bottom: 5px;
}

.bottom-border{
    border-bottom: 2px currentColor solid;
    padding-bottom: 10px;
}

.top-border-thin{
    border-top: 1px currentColor solid;


}

@media screen and (min-width: 0px) and (max-width: 800px) {

.double-border{
    padding: 10px 0px;
}

.top-border{
    padding-top: 10px;
    padding-bottom: 5px;
}
.double-border-accordion{
}

}
