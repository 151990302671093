.article-image-float-right img{
  display: flex;
  width: 100%;
    margin-bottom: 20px;
}

.article-image-float-left img{
  display: flex;
  width: 100%;
    margin-bottom: 20px;
}

.article-image-float-center img{
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.article-image-float-right h3 p{
    position: relative;
    top: -15px;
    width: 40%;
    font-size: 12px;
    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: -0.0025em;
    word-spacing: -0.0025em;
    color: #888888;
}

.article-image-float-left h3 p{
    position: relative;
    top: -15px;
    width: 40%;
    font-size: 12px;
    line-height: 1.25em;
    font-weight: 500;
    letter-spacing: -0.0025em;
    word-spacing: -0.0025em;
    color: #888888;
}


.article-header{
  margin-bottom: 15px;
}

.article-header h1{
  width: 95%;
}

.article-aside{
  position: absolute;
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: 0px currentColor solid;
  opacity: 1;
}

.published{
  margin-bottom: 15px;
}

.published p{
  margin-bottom: 0px;
}

.related{
    border-top: 1px currentColor solid;
    padding-top: 4px;
    margin-bottom: 35px;
}

.related-posts{
    border-top: 1px currentColor solid;
    padding-top: 4px;
    padding-bottom: 15px;
}
.related-posts p{
    width: 95%;
    margin-bottom: 0px;
}

blockquote{
    border-left: 1px currentColor solid;
    margin-left: -60px;
    top: 2px;
    padding-left: 20px;
    position: relative;
}

.article-text h2{
  margin-bottom: 20px;
  top: 4px;
  width: 90%;
  position: relative;

}


@media screen and (min-width: 0px) and (max-width: 799px) {

  .article-header h1{
  width: 100%;
  }

  .article-aside{
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 15px;
    border-bottom: 1px currentColor solid;
  }

  blockquote{
      border-left: 1px currentColor solid;
      margin-left: 0px;
      width: 110%;

  }

  blockquote p{
      font-size: 18px;
  }
    }
