
.landing{
    height: 100vh;
    max-height: 1300px;
    min-height: 800px;
    position: relative;

}

.landing-titles{
    margin-top: 16px;
    border-top: 2px currentColor solid;
    padding-top: 0px;
}

.landing-titles .left{
    margin-left: 1.5em;
}


.landing-titles .right{
    text-align: right;
    border-bottom: 0px currentColor solid;
}

.landing-description h2{
    width: 70%;
    margin-top: 2px;
}



.landing .landing-image{
    position: absolute;
    bottom: 28px;
    z-index: -1;
}

.hero-image{
  position: relative;
  display: block;
  height: 65vh;
  max-height: 800px;
  min-height: 500px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing .landing-image{
    width: calc(100% + 40px);
    margin-left: -15px;
}


.homepage-menu{
    float: right;
    text-align: right;
}

.homepage-menu li {
    display: inline;
    margin-left: 0px;
}

.margin-10{
    display: inline;
    margin-left: 10px;
}

.margin-right-10{
    display: inline;
    margin-right: 10px;
}


.homepage-menu {
    width: 100%;
    border-top: 0px currentColor solid;
}

.homepage-menu li a h2{
    display: inline;
    border-bottom: 2px currentColor solid;
    padding-bottom: .05em;
    transition: 0s;
}

.homepage-menu li a h2:hover{
    border-bottom: 0px currentColor solid;
    padding-bottom: calc(2px + .05em);
    transition: 0s;
}


.inline-svg svg{
  position: relative;
    display: inline-block;
    top: 4px;
    width: 1.25em

}

.inline-svg p{
    display: inline-flex;
    margin-left: 10px;
    top: -1px;
    position: relative;
}

.recentwriting {
  margin-top: 50px;
  margin-bottom: -80px;
}


@media screen and (min-width: 0px) and (max-width: 799px) {

  .landing-titles .left{
      margin-left: 0em;
      font-size: 24px;
  }
.mobile-remove{
  display: none;
}
  .landing{
      max-height: 1000px;
      min-height: 00px;

  }
  .inline-svg svg{
    position: relative;
      display: inline-block;
      top: 3px;
      width: 1.1em;

  }
    .landing .landing-image{
      width: 100%;
      margin-left: 0px;
  }
  .hero-image{
    position: relative;
    display: block;
    height: 50vh;
    max-height: 800px;
    min-height: 00px;
  }
.landing-titles{
border-bottom: 2px currentColor solid;
padding-bottom: 10px;

}
  .landing-titles .right{
  font-size: 24px;
  }
  .landing-description h2{
      width: 95%;
  }
  .homepage-menu {
  border-top: 2px currentColor solid;
    margin-top: 20px;
      width: 100%;
  }


}
@media screen and (min-width: 800px) and (max-width: 1100px) {
  .landing .landing-image{
      width: 100%;
      margin-left: 0px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1679px) {

  .homepage-menu {
      width: 80%;
  }
}
@media screen and (min-width: 1680px) and (max-width: 1999px) {

  .homepage-menu {
      width: 100%;
  }
}
@media screen and (min-width: 2000px) and (max-width: 10000px) {
  .landing-description h2{
      width: 60%;
  }
  .homepage-menu {
      width: 80%;
  }

}
