

.articles {
    padding: 0px 5px 30px 5px;

}
.featured-articles {
    padding: 0px 0px 30px 0px;

}
.featured-articles:nth-of-type(1) {
    padding: 0px 10px 30px 0px;

}

.featured-articles:nth-of-type(3) {
    padding: 0px 0px 30px 10px;

}



.articles .articles-header {
    min-height: 75px;
    margin-top: 2px;
    display: block;

}
.articles-hero-image{
  width: 100%;
  height: 300px;
  opacity: .75;
  filter: grayscale(100%);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.articles-hero-image:hover{
  filter: grayscale(0%);
  opacity: 1;
}

.articles-read-more{
  float: right;
  opacity: .5;
    margin-right: 15px;
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .articles-hero-image{
    height: 250px;
  }
    }
@media screen and (min-width: 700px) and (max-width: 1099px) {
  .articles-hero-image{
    height: 325px;
  }
  .featured-articles:nth-of-type(1) {
      padding: 0px 5px 30px 5px;

  }
  .featured-articles:nth-of-type(3) {
      padding: 0px 5px 30px 5px;

      .featured-articles {
          padding: 0px 5px 30px 5px;

      }
  }
    }
    @media screen and (min-width: 0px) and (max-width: 699px) {
      .articles-hero-image{
        height: 250px;
      }
      .featured-articles:nth-of-type(1) {
          padding: 0px 5px 30px 5px;
            margin-top: 0px;
              margin-bottom: 35px;

      }
      .featured-articles:nth-of-type(3) {
          padding: 0px 5px 30px 5px;

      }
      .featured-articles {
        margin-top: 20px;
          padding: 0px 5px 30px 5px;
            margin-bottom: 35px;

      }
        }

.articles-top {

  margin-top: 128px;
}


.articles-links{
  margin-top: 6px;
}


.tags{
  margin-left: 15px;
}

.tags svg{

    position: absolute;
      display: inline;
      width: 1.1rem;
      margin-top: -.05em;
}

.tags a{
      margin-left: 0px;
}

.tags a:nth-of-type(1){
      margin-left: 25px;
}

@media screen and (min-width: 1680px) and (max-width: 10000px) {

  .tags a{
        font-size: 20px;
      }
      .articles-read-more{
            font-size: 20px;
      }
    }

@media screen and (min-width: 600px) and (max-width: 1300px) {

    .tags a{
          font-size: 16px;
        }
        .articles-read-more{
              font-size: 16px;
        }
      }

@media screen and (min-width: 0px) and (max-width: 599px) {
  .tags a{
        font-size: 14px;
  }
  .articles-read-more{
        font-size: 14px;
  }
      }
