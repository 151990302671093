.footer{
    width: 100%;
    height: 100vh;
    max-height: 1600px;
}

.more-articles{
  margin-bottom: 5px;
}

.featured-articles{
    height: 50vh;
    max-height: 500px;
    width: 100%;
}

.footer-content{
    width: 100%;
    opacity: .5;
}

.footer-content {
    color: #888888;
}

.footer-content .grid-wrapper{
    color: #888888;
    position: absolute;
    width: 100%;
    bottom: 0px;
}

.footer-copyright {
    color: #888888;
    position: relative;
}


.footer-top {
    text-align: right;
    color: #888888;
    width: 100%;
}


.footerarticles{
    width: 100%;
    height: 50vh;
    max-height: 900px;
}

@media screen and (min-width: 0px) and (max-width: 599px) {

  .footer{
      height: 1600px;
      max-height: 3000px;
  }
    }
